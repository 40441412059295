<div class="azure-ad-sso">
  <div class="azure-ad-sso__intro">
    <h1>MUBADALA</h1>
    <h2>RAVEN</h2>
  </div>

  <div class="azure-ad-sso__actions">
    <button class="primary" id="login" (click)="login()">Login</button>
    <button class="secondary" id="logout" (click)="logout()">Logout</button>
  </div>
</div>
